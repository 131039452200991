














































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { VTextField } from "vuetify/lib";
import { FileModel } from "@/model/file/fileModel";
import { LoadingStatusType } from "@/enums/enums";
const StoreAccountingFrim = namespace("AccountingFrim");
@Component({ components: {} })
export default class AccountingFirmCreate extends Vue {
  private internalCode = "";
  private namethai = "";
  private nameeng = "";
  private afprefcode = "";
  private contactname = "";
  private address = "";
  private phonenumber = "";
  private email = "";
  private color = "";
  private logoUrl: any = {};
  private renderImg = true;

  @StoreAccountingFrim.Action
  private DoGetAccountingFirmInternalCode!: () => void;
  @StoreAccountingFrim.Getter
  private getAccountingFirmInternalCode!: any;
  @StoreAccountingFrim.Getter
  private getInsertAccountingFirmLoadingStatus!: any;
  @StoreAccountingFrim.Getter
  private getAccountingFirmsDetail!: any | null;
  @StoreAccountingFrim.Action
  private DoInsertAccountingFirm!: (inputRequest: any) => void;

  validateItemRequired(value: string | null | undefined): boolean | string {
    if (!value) {
      return "Item is required";
    }
    if (value.trim().length > 256) {
      return "Input exceeds the maximum length";
    }
    return true;
  }
  validateAddress(value: string | null | undefined): boolean | string {
    if (!value) {
      return "Address is required";
    }
    if (value.trim().length > 1000) {
      return "Address exceeds the maximum length";
    }
    return true;
  }
  validatePhoneNumber(value: string | null | undefined): boolean | string {
    if (!value) {
      return "Phone Number is required";
    }
    if (value.trim().length > 50) {
      return "Phone Number exceeds the maximum length";
    }
    return true;
  }

  created() {
    this.DoGetAccountingFirmInternalCode();
    this.SetInternalCode();
  }
  public SetInternalCode() {
    this.internalCode = this.getAccountingFirmInternalCode;
  }

  @Watch("getInsertAccountingFirmLoadingStatus", { immediate: true })
  getInsertAccountingFirmLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      alert(`Create ${this.getAccountingFirmInternalCode} Successfully.`);
      this.AccountingFirmDetail(this.getAccountingFirmsDetail.id);
    }
  }

  public GetFileModel(file: any) {
    const fileType = file.getChosenFile();
    return file.getChosenFile();
  }
  public Save() {
    let fields = [
      this.$refs.namethaiField,
      this.$refs.nameengField,
      this.$refs.contactnameField,
      this.$refs.addressField,
      this.$refs.phonenumberField,
      this.$refs.emailField,
    ];

    let isValid = true;
    fields.forEach((element: any) => {
      if (element && !element.validate()) {
        isValid = false;
        element.validate(true);
      }
    });
    if (isValid) {
      let logoUrl: FileModel | null = this.logoUrl;
      if (this.logoUrl.hasImage()) {
        logoUrl = this.GetFileModel(this.logoUrl);
      } else {
        logoUrl = null;
      }
      const inputRequest: any = {
        name: this.namethai.trim(),
        nameEn: this.nameeng.trim(),
        afpRefCode: this.afprefcode,
        internalCode: this.internalCode,
        contactName: this.contactname.trim(),
        address: this.address.trim(),
        phoneNumber: this.phonenumber.trim(),
        email: this.email.trim(),
        logoUrl: logoUrl,
        isDeleted: true,
      };
      this.DoInsertAccountingFirm(inputRequest);
    } else {
      alert("กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง");
    }
  }
  public AccountingFirmDetail(id: any) {
    const routeData = this.$router.resolve({
      name: "accountingFirmDetail",
      query: { id: id },
    });
    window.location.href = routeData.href;
  }
  public Cancel() {
    this.$router.push({
      name: "accountingFirmTable",
    });
  }
}
